document.addEventListener('turbolinks:load', function () {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-slide-in'); // Tailwind animation class
          observer.unobserve(entry.target); // Stop observing once the animation is triggered
        }
      });
    }, { threshold: 0.1 }); // Trigger when 10% of the element is visible

    const cards = document.querySelectorAll('.slide-in-trigger');
    cards.forEach(card => {
      observer.observe(card);
    });
  });
